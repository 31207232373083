import { ua } from './modules/ua'
import { klass, scrollSmooth, inViewSet, mountSwipeAnimation } from './modules/common'
import { mountFirstViewAnimation } from './modules/firstViewAnimation'
import { accordion } from './modules/accordion'
import { modal } from './modules/modal'
import { LuminousGallery } from 'luminous-lightbox'

class App {
  constructor() {
    this.body = document.querySelector('body')
    this.html = document.querySelector('html')
    this.init()
  }

  init() {
    // 全ページ共通適用
    this.body.classList.add(`is-${ua.browser()}`)
    this.body.classList.add(`is-${ua.os()}`)
    this.initPages()
    this.changeLectureNavigation()
    mountFirstViewAnimation()
    accordion()
    modal()
    mountSwipeAnimation()
  }

  initPages() {
    inViewSet()

    // ナビゲーション開閉
    document.querySelector('.navigation-trigger').addEventListener('click', () => {
      this.body.classList.toggle(klass.nav)
      this.html.classList.toggle(klass.scrollLock)
    })

    // スムーススクロール
    const smoothScrollTarget = Array.from(document.querySelectorAll('.js-smooth-scroll'))
    if (smoothScrollTarget.length > 0) {
      smoothScrollTarget.forEach((link) => {
        link.addEventListener('click', (e) => {
          e.preventDefault()
          const targetId = e.target.hash
          document.querySelector('body').classList.remove('view-nav')

          if (!document.querySelector(targetId)) {
            return false
          }
          scrollSmooth(targetId)
        })
      })
    }

    const galleryImages = document.querySelectorAll('.wp-block-gallery a')
    if (galleryImages) {
      new LuminousGallery(galleryImages)
    }
  }

  changeLectureNavigation() {
    const select = document.querySelector('[name="lecture-select-navigation"]')
    if (select) {
      select.addEventListener('change', () => {
        window.location.href = select.value
      })
    }
  }
}

document.addEventListener('DOMContentLoaded', () => {
  window.shibukawa = new App()
})

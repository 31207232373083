const mountFirstViewAnimation = () => {
  /**
   * トップロード完了時にアニメーション
   */
  window.addEventListener('load', () => {
    setTimeout(() => {
      if (document.querySelector('.loader')) {
        const body = document.querySelector('body')
        const loader = document.querySelector('.loader')
        const logo = document.querySelector('.loader__logo')
        body.style.overflow = 'hidden'
        setTimeout(() => {
          logo.classList.add('is-visible')
        }, 1000)
        setTimeout(() => {
          logo.classList.add('is-scale-out')
        }, 4000)
        setTimeout(() => {
          loader.classList.add('is-hidden')
          body.style.overflow = 'auto'
        }, 5000)
      }
    }, 300)
  })
}

export { mountFirstViewAnimation }

const modal = () => {
  /**
   * modal function
   */
  if (document.querySelector('.js-modal-open')) {
    const modalOpen = document.querySelector('.js-modal-open')
    const modalClose = document.querySelectorAll('.js-modal-close')
    const body = document.querySelector('body')

    modalOpen.addEventListener('click', () => {
      body.classList.add('is-view-modal')
    })

    modalClose.forEach((elm) => {
      elm.addEventListener('click', () => {
        body.classList.remove('is-view-modal')
      })
    })
  }
}

export { modal }

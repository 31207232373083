const accordion = () => {
  /**
   * accordion function
   */

  const accordionTrigger = Array.from(document.querySelectorAll('.js-accordion-title'))
  const accordionBody = Array.from(document.querySelectorAll('.js-accordion-body'))

  window.addEventListener('load', () => {
    accordionBody.forEach((el) => {
      const height = el.scrollHeight
      el.style.setProperty('--max-height', height + 'px')
    })
  })

  window.addEventListener('resize', () => {
    accordionBody.forEach((el) => {
      const height = el.scrollHeight
      el.style.setProperty('--max-height', height + 'px')
    })
  })

  accordionTrigger.forEach((el) => {
    el.addEventListener('click', () => {
      el.classList.toggle('is-open')
      el.nextElementSibling.classList.toggle('is-open')
    })
  })
}
export { accordion }

import inView from 'in-view'
import smoothscroll from 'smoothscroll-polyfill'
import lottie from 'lottie-web'

const klass = {
  active: 'is-active',
  fixed: 'is-fixed',
  view: 'is-view',
  selected: 'is-selected',
  hidden: 'is-hidden',
  eventNone: 'is-eventNone',
  landscape: 'is-landscape',
  current: 'is-current',
  large: 'is-large',
  nav: 'view-navigation',
  bottom: 'is-bottom',
  dark: 'is-dark',
  scrollLock: 'lock-scroll',
}

/**
 * Get the URL parameter value
 *
 * @param name {string} パラメータのキー文字列
 * @param url {string} 対象のURL文字列（任意）パラメータのキー文字列
 * @return string
 */
const getParam = (name, url) => {
  if (!url) url = window.location.href
  name = name.replace(/[\[\]]/g, '\\$&')
  var regex = new RegExp('[?&]' + name + '(=([^&#]*)|&|#|$)'),
    results = regex.exec(url)
  if (!results) return null
  if (!results[2]) return ''
  return decodeURIComponent(results[2].replace(/\+/g, ' '))
}

/**
 * scroll inView animation
 */
const inViewSet = () => {
  inView('.inview').on('enter', (el) => {
    el.classList.add('is-view')
  })

  inView('.js-fadeUp').on('enter', (el) => {
    el.classList.add('is-view')
  })

  inView('.js-fadeLeft').on('enter', (el) => {
    el.classList.add('is-view')
  })

  inView('.js-fadeIn').on('enter', (el) => {
    el.classList.add('is-view')
  })
}

/**
 * モバイルサイズ判定
 * @return {boolean}
 */
const forMobile = () => window.innerWidth < 768

/**
 * スムーススクロール
 */
const scrollSmooth = (targetId, transion) => {
  smoothscroll.polyfill()
  const behavior = transion || 'smooth'
  const rectTop = document.querySelector(targetId).getBoundingClientRect().top
  const top = rectTop + window.scrollY - document.querySelector('.header').clientHeight
  window.scrollTo({ top, behavior })
}

/**
 * ギャラリー SP時スワイプアニメーション
 */
const mountSwipeAnimation = () => {
  const lottieSwipeAnimationInit = {
    container: document.getElementById('swipeMotion'),
    renderer: 'svg',
    loop: false,
    autoplay: false,
    path: '/wp-content/themes/shibukawa-hachimangu_v1/assets/json/swipe-left-gesture-animation.json',
  }

  const lottieSwipeAnimation = lottie.loadAnimation(lottieSwipeAnimationInit)

  inView('.inview').on('enter', () => {
    setTimeout(() => {
      lottieSwipeAnimation.play()
    }, 500)
  })
}

export { klass, forMobile, inViewSet, getParam, scrollSmooth, mountSwipeAnimation }
